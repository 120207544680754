export const templateList = {
    alexandrineEnglish: {
        name: "Alexandrine/Alexandrine (English)",
        details: "",
        fixedLines: false,
        rhymed: false,
        stressed: true,
        lines: [{ syllables: "010101|010101" }]
    },
    alexandrineFrench: {
        name: "Alexandrine/Alexandrine (French)",
        details: "",
        fixedLines: false,
        rhymed: false,
        stressed: true,
        lines: [{ syllables: "xxxxx1|xxxxx1" }]
    },
    balladABAB: {
        name: "Ballad/Ballad (ABAB Common)",
        details: "",
        fixedLines: true,
        rhymed: true,
        stressed: false,
        lines: [
            { syllables: "01010101", rhyme: "A" },
            { syllables: "010101", rhyme: "B" },
            { syllables: "01010101", rhyme: "C" },
            { syllables: "010101", rhyme: "B" },
            { break: true },
            { syllables: "01010101", rhyme: "A" },
            { syllables: "010101", rhyme: "B" },
            { syllables: "01010101", rhyme: "C" },
            { syllables: "010101", rhyme: "B" },
            { break: true },
            { syllables: "01010101", rhyme: "A" },
            { syllables: "010101", rhyme: "B" },
            { syllables: "01010101", rhyme: "C" },
            { syllables: "010101", rhyme: "B" },
            { break: true },
            { syllables: "01010101", rhyme: "A" },
            { syllables: "010101", rhyme: "B" },
            { syllables: "01010101", rhyme: "C" },
            { syllables: "010101", rhyme: "B" },
            { break: true },
            { syllables: "01010101", rhyme: "A" },
            { syllables: "010101", rhyme: "B" },
            { syllables: "01010101", rhyme: "C" },
            { syllables: "010101", rhyme: "B" },
            { break: true },
            { syllables: "01010101", rhyme: "A" },
            { syllables: "010101", rhyme: "B" },
            { syllables: "01010101", rhyme: "C" },
            { syllables: "010101", rhyme: "B" },
            { break: true },
            { syllables: "01010101", rhyme: "A" },
            { syllables: "010101", rhyme: "B" },
            { syllables: "01010101", rhyme: "C" },
            { syllables: "010101", rhyme: "B" },
            { break: true },
            { syllables: "01010101", rhyme: "A" },
            { syllables: "010101", rhyme: "B" },
            { syllables: "01010101", rhyme: "C" },
            { syllables: "010101", rhyme: "B" },
            { break: true },
            { syllables: "01010101", rhyme: "A" },
            { syllables: "010101", rhyme: "B" },
            { syllables: "01010101", rhyme: "C" },
            { syllables: "010101", rhyme: "B" },
            { break: true },
            { syllables: "01010101", rhyme: "A" },
            { syllables: "010101", rhyme: "B" },
            { syllables: "01010101", rhyme: "C" },
            { syllables: "010101", rhyme: "B" },
            { break: true },
            { syllables: "01010101", rhyme: "A" },
            { syllables: "010101", rhyme: "B" },
            { syllables: "01010101", rhyme: "C" },
            { syllables: "010101", rhyme: "B" },
            { break: true },
            { syllables: "01010101", rhyme: "A" },
            { syllables: "010101", rhyme: "B" },
            { syllables: "01010101", rhyme: "C" },
            { syllables: "010101", rhyme: "B" },
            { break: true },
            { syllables: "01010101", rhyme: "A" },
            { syllables: "010101", rhyme: "B" },
            { syllables: "01010101", rhyme: "C" },
            { syllables: "010101", rhyme: "B" },
            { break: true },
            { syllables: "01010101", rhyme: "A" },
            { syllables: "010101", rhyme: "B" },
            { syllables: "01010101", rhyme: "C" },
            { syllables: "010101", rhyme: "B" },
            { break: true }
        ]
    },
    balladABCB: {
        name: "Ballad/Ballad (ABCB Common)",
        details: "",
        fixedLines: true,
        rhymed: true,
        stressed: false,
        lines: [
            { syllables: "01010101", rhyme: "A" },
            { syllables: "010101", rhyme: "B" },
            { syllables: "01010101", rhyme: "C" },
            { syllables: "010101", rhyme: "B" },
            { break: true },
            { syllables: "01010101", rhyme: "A" },
            { syllables: "010101", rhyme: "B" },
            { syllables: "01010101", rhyme: "C" },
            { syllables: "010101", rhyme: "B" },
            { break: true },
            { syllables: "01010101", rhyme: "A" },
            { syllables: "010101", rhyme: "B" },
            { syllables: "01010101", rhyme: "C" },
            { syllables: "010101", rhyme: "B" },
            { break: true },
            { syllables: "01010101", rhyme: "A" },
            { syllables: "010101", rhyme: "B" },
            { syllables: "01010101", rhyme: "C" },
            { syllables: "010101", rhyme: "B" },
            { break: true },
            { syllables: "01010101", rhyme: "A" },
            { syllables: "010101", rhyme: "B" },
            { syllables: "01010101", rhyme: "C" },
            { syllables: "010101", rhyme: "B" },
            { break: true },
            { syllables: "01010101", rhyme: "A" },
            { syllables: "010101", rhyme: "B" },
            { syllables: "01010101", rhyme: "C" },
            { syllables: "010101", rhyme: "B" },
            { break: true },
            { syllables: "01010101", rhyme: "A" },
            { syllables: "010101", rhyme: "B" },
            { syllables: "01010101", rhyme: "C" },
            { syllables: "010101", rhyme: "B" },
            { break: true },
            { syllables: "01010101", rhyme: "A" },
            { syllables: "010101", rhyme: "B" },
            { syllables: "01010101", rhyme: "C" },
            { syllables: "010101", rhyme: "B" },
            { break: true },
            { syllables: "01010101", rhyme: "A" },
            { syllables: "010101", rhyme: "B" },
            { syllables: "01010101", rhyme: "C" },
            { syllables: "010101", rhyme: "B" },
            { break: true },
            { syllables: "01010101", rhyme: "A" },
            { syllables: "010101", rhyme: "B" },
            { syllables: "01010101", rhyme: "C" },
            { syllables: "010101", rhyme: "B" },
            { break: true },
            { syllables: "01010101", rhyme: "A" },
            { syllables: "010101", rhyme: "B" },
            { syllables: "01010101", rhyme: "C" },
            { syllables: "010101", rhyme: "B" },
            { break: true },
            { syllables: "01010101", rhyme: "A" },
            { syllables: "010101", rhyme: "B" },
            { syllables: "01010101", rhyme: "C" },
            { syllables: "010101", rhyme: "B" },
            { break: true },
            { syllables: "01010101", rhyme: "A" },
            { syllables: "010101", rhyme: "B" },
            { syllables: "01010101", rhyme: "C" },
            { syllables: "010101", rhyme: "B" },
            { break: true },
            { syllables: "01010101", rhyme: "A" },
            { syllables: "010101", rhyme: "B" },
            { syllables: "01010101", rhyme: "C" },
            { syllables: "010101", rhyme: "B" },
            { break: true },
            { syllables: "01010101", rhyme: "A" },
            { syllables: "010101", rhyme: "B" },
            { syllables: "01010101", rhyme: "C" },
            { syllables: "010101", rhyme: "B" },
            { break: true }
        ]
    },
    cinquainBasic: {
        name: "Cinquain/Cinquain (Basic)",
        details: "",
        fixedLines: true,
        rhymed: false,
        stressed: false,
        lines: [
            { syllables: "xx" },
            { syllables: "xxxx" },
            { syllables: "xxxxxx" },
            { syllables: "xxxxxxxx" },
            { syllables: "xx" }
        ]
    },
    haikuBasic: {
        name: "Haiku/Haiku (Basic)",
        details: "",
        rhymed: false,
        stressed: false,
        fixedLines: true,
        lines: [
            { syllables: "xxxxx" },
            { syllables: "xxxxxxx" },
            { syllables: "xxxxx" }
        ]
    },
    sonnetShakespearean: {
        name: "Sonnet/Sonnet (Shakespearean)",
        details: "",
        fixedLines: true,
        rhymed: true,
        stressed: true,
        lines: [
            { syllables: "0101010101", rhyme: "A" },
            { syllables: "0101010101", rhyme: "B" },
            { syllables: "0101010101", rhyme: "A" },
            { syllables: "0101010101", rhyme: "B" },
            { break: true },
            { syllables: "0101010101", rhyme: "C" },
            { syllables: "0101010101", rhyme: "D" },
            { syllables: "0101010101", rhyme: "C" },
            { syllables: "0101010101", rhyme: "D" },
            { break: true },
            { syllables: "0101010101", rhyme: "E" },
            { syllables: "0101010101", rhyme: "F" },
            { syllables: "0101010101", rhyme: "E" },
            { syllables: "0101010101", rhyme: "F" },
            { break: true },
            { syllables: "0101010101", rhyme: "G" },
            { syllables: "0101010101", rhyme: "G" }
        ]
    },
    sonnetPetrarchan: {
        name: "Sonnet/Sonnet (Petrarchan)",
        details: "",
        fixedLines: true,
        rhymed: true,
        stressed: true,
        lines: [
            { syllables: "0101010101", rhyme: "A" },
            { syllables: "0101010101", rhyme: "B" },
            { syllables: "0101010101", rhyme: "B" },
            { syllables: "0101010101", rhyme: "A" },
            { syllables: "0101010101", rhyme: "A" },
            { syllables: "0101010101", rhyme: "B" },
            { syllables: "0101010101", rhyme: "B" },
            { syllables: "0101010101", rhyme: "A" },
            { break: true },
            { syllables: "0101010101", rhyme: "C" },
            { syllables: "0101010101", rhyme: "D" },
            { syllables: "0101010101", rhyme: "D" },
            { syllables: "0101010101", rhyme: "E" },
            { syllables: "0101010101", rhyme: "C" },
            { syllables: "0101010101", rhyme: "E" }
        ]
    }
};
